import React from "react"
import { Layout } from "~/src/components"
import getContext from "context"

const NotFound = props => {
  const { t } = getContext()
  return (
    <Layout title="Not Found" pageType="not-found" url={props.uri}>
      <div className="container max-w-lg mx-auto p-12 py-32 text-center text-2xl font-bold">
        {t("common.not-found")}
      </div>
    </Layout>
  )
}

export default NotFound
